import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import PropTypes from 'prop-types';
import { initReactI18next, useTranslation } from 'react-i18next';

import englishTranslation from './translations/en/en.json';
import frenchTranslation from './translations/fr/fr.json';
import germanTranslation from './translations/de/de.json';
import italianTranslation from './translations/it/it.json';
import spanishTranslation from './translations/es/es.json';

const isDev = import.meta.env.DEV;

const project = import.meta.env.VITE_PROJECT;

let PROJECT_NS_TRANSLATIONS = {};

if (project === 'beeyou') {
	PROJECT_NS_TRANSLATIONS = {
		Domain: 'beeyou.tv',
		Name: 'Beeyou',
		'Name.Short': 'BYTV',
	};
}

if (project === 'comvision') {
	PROJECT_NS_TRANSLATIONS = {
		Domain: 'commandovision.com',
		Name: 'Commando Vision',
		'Name.Short': 'CV',
	};
}

const withProjectNs = (translation) => ({
	project: PROJECT_NS_TRANSLATIONS,
	...translation,
});

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// we init with resources
		resources: {
			de: withProjectNs(germanTranslation),
			en: withProjectNs(englishTranslation),
			es: withProjectNs(spanishTranslation),
			fr: withProjectNs(frenchTranslation),
			it: withProjectNs(italianTranslation),
		},
		fallbackLng: 'en',
		debug: isDev,

		// have a common namespace used around the full app
		ns: ['project', 'translations'],
		defaultNS: 'translations',

		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false,
		},

		react: {
			transKeepBasicHtmlNodesFor: ['br'],
		},
	});

export default i18n;

export const getLanguageKey = (language) => (
	i18n.services.languageUtils.getLanguagePartFromCode(language)
);

export const Translate = ({ k }) => {
	const { t } = useTranslation();
	return t(k);
};

Translate.propTypes = {
	k: PropTypes.string.isRequired,
};
