// @ts-check
/* eslint-disable react/prop-types */

import { ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';

import { ViewError } from '../../views/Error/Error';

/**
 * @typedef {import('react').ComponentProps<typeof RollbarErrorBoundary>} ErrorBoundaryProps
 */

/** @type {React.FC<ErrorBoundaryProps>} */
export const ErrorBoundary = ({
	children,
	fallbackUI = ViewError,
	...props
}) => (
	<RollbarErrorBoundary fallbackUI={fallbackUI} {...props}>
		{children}
	</RollbarErrorBoundary>
);
