/* eslint-disable react/prop-types */
// @ts-check

import clsx from 'clsx';
import { forwardRef } from 'react';

import { isSafari } from '../../lib/userAgent';

import './ReallyPerfectScrollbar.scss';

/**
 * @typedef {{
* 	children?: React.ReactNode,
* 	className?: string,
* 	enabled?: boolean,
* 	forwardedRef?: React.ForwardedRef<HTMLDivElement>,
* 	orientation?: 'horizontal' | 'vertical',
* } & React.HTMLProps<HTMLDivElement>} ReallyPerfectScrollbarProps
*/

const ReallyPerfectScrollbar = (
	/** @type {ReallyPerfectScrollbarProps} */
	{
		children,
		className,
		enabled = true,
		forwardedRef = null,
		orientation = 'vertical',
		...props
	},
) => {
	if (!enabled) {
		return <div className={className} ref={forwardedRef} {...props}>{children}</div>;
	}

	return (
		<div
			className={clsx('ReallyPerfectScrollbar', className, { isSafari }, orientation)}
			ref={forwardedRef}
			{...props}
		>
			{children}
		</div>
	);
};

export default forwardRef(
	(
		/** @type {Omit<ReallyPerfectScrollbarProps, 'forwardedRef'>} */
		props,
		/** @type {React.ForwardedRef<HTMLDivElement> | undefined} */
		ref,
	) => <ReallyPerfectScrollbar {...props} forwardedRef={ref} />,
);
