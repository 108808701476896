// @ts-check
/* eslint-disable react/prop-types */

import { Trans, useTranslation } from 'react-i18next';
import { FaExclamation } from 'react-icons/fa';
import { useMatch } from 'react-router-dom';

import { getLink, Path } from '../../RoutePath';
import { ButtonPill, ButtonPillOutline } from '../../components/Button';

// Errors from Chrome | Safari | Firefox
const importFailedMessage = /.*(?:Failed to fetch dynamically imported module)|(?:Importing a module script failed)|(?:error loading dynamically imported module).*/i;

/**
 * Returns true if error message contains "Loading chunk failed"
 * @param {Error | null} error
 * @returns {boolean}
 */
const isImportFailedError = (error) => !!(
	error?.message
	&& importFailedMessage.test(error.message)
);

/**
 * @typedef {{ error: Error | null }} ViewErrorProps
 */

/** @type {React.FC<ViewErrorProps>} */
export const ViewError = ({ error }) => {
	const { t } = useTranslation();
	const isHomePage = !!useMatch({ path: Path.ROOT, end: true });

	const showOutdatedApp = isImportFailedError(error);

	const handleReload = () => {
		window.location.reload();
	};

	return (
		<div className="h-100 w-100 position-absolute d-flex align-items-center justify-content-center">
			<div className="ViewError d-flex flex-column justify-content-center align-items-center text-center">
				<FaExclamation
					size="5em"
					className={showOutdatedApp ? 'text-success' : 'text-danger'}
				/>
				<h2 className="mt-3">
					<Trans
						i18nKey={showOutdatedApp
							? 'Error.Error.outdatedApp'
							: 'Error.Error.errorOccurred'}
					/>
				</h2>
				{!showOutdatedApp && (
					<p className="font-size-sm">
						{error && error.toString()}
					</p>
				)}
				<p className="mt-3">
					<ButtonPill
						className="shadow-none btn-transition-none"
						color="primary"
						onClick={handleReload}
						tag="a"
						size="lg"
					>
						{t(showOutdatedApp ? 'Error.Error.update' : 'Error.Error.reload')}
					</ButtonPill>
				</p>
				{!isHomePage && (
					<p>
						<ButtonPillOutline
							className="shadow-none btn-transition-none"
							color="primary"
							tag="a"
							href={getLink(Path.ROOT)}
							size="lg"
						>
							{t('Error.Error.returnToHome')}
						</ButtonPillOutline>
					</p>
				)}
			</div>
		</div>
	);
};
